<template>
  <div>
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box">
      <div class="content-main mb24px">
        <!-- 搜索 -->
        <div class="search-box">
          <div class="ayl-item" style="width: 200px">
            <span>车辆归属</span>
            <el-select
              class="item"
              v-model="query.carBelong"
              clearable
              placeholder="请选择"
              @change="queryChange"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="ayl-item" style="width: 180px">
            <el-input
              placeholder="车牌号码"
              v-model="query.queryContent"
              clearable
              @input="queryChange"
            >
            </el-input>
          </div>
          <el-button type="primary" size="mini" @click="onSearch"
            >查询</el-button
          >
          <el-button
            type="warning"
            style="margin-left: 20px"
            @click="$router.push('/vehicle-management/add-car')"
            >新增</el-button
          >
        </div>
        <!-- 表格 -->
        <div class="table-box">
          <el-table
            v-loading="loading"
            :header-cell-style="getRowClass"
            :data="tableData"
            style="width: 100%"
          >
            <el-table-column
              prop="plateNumber"
              fixed="left"
              label="车牌号码"
            >
              <template slot-scope="scope">{{
                scope.row.plateNumber | isNull
              }}</template>
            </el-table-column>
            <el-table-column
              prop="teamName"
              fixed="left"
              label="所属车队"
            >
              <template slot-scope="scope">{{
                scope.row.teamName | isNull
              }}</template>
            </el-table-column>
            <el-table-column
              prop="carBlong"
              align="center"
              width="100px"
              fixed="left"
              label="车辆归属"
            >
              <template slot-scope="scope">{{
                scope.row.carBlong | isNull
              }}</template>
            </el-table-column>
            <el-table-column
              prop="carSatelliteType"
              fixed="left"
              label="车辆类型"
            >
              <template slot-scope="scope">{{
                scope.row.carSatelliteType | isNull
              }}</template>
            </el-table-column>
            <el-table-column
              prop="annualInspectionEndTime"
              fixed="left"
              align="center"
              width="100px"
              label="年检有效期"
            >
              <template slot-scope="scope">
                <span v-if="!scope.row.annualInspectionEndTime">--</span>
                <span v-else>{{
                  scope.row.annualInspectionEndTime | isNull | str2ymd
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="carUnit"
              fixed="left"
              width="200px"
              label="挂靠单位"
            >
              <template slot-scope="scope">{{
                scope.row.carUnit | isNull
              }}</template>
            </el-table-column>
            <el-table-column
              prop="carImageUrlList"
              align="center"
              width="100px"
              label="车辆图片"
            >
              <template slot-scope="scope">
                <el-button
                  v-if="scope.row.carImageUrlList.length > 0"
                  type="text"
                  size="small"
                  @click="carData(scope.row)"
                  >{{ scope.row.carImageUrlList.length }}</el-button
                >
                <el-button v-else type="text" size="small" disabled
                  >0</el-button
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="driverLicenseImageUrlList"
              align="center"
              width="100px"
              label="行驶证图片"
            >
              <template slot-scope="scope">
                <el-button
                  v-if="scope.row.driverLicenseImageUrlList.length > 0"
                  type="text"
                  size="small"
                  @click="driver(scope.row)"
                  >{{ scope.row.driverLicenseImageUrlList.length }}</el-button
                >
                <el-button v-else type="text" size="small" disabled
                  >0</el-button
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="transportLicenseImageUrlList"
              align="center"
              width="100px"
              label="运输证图片"
            >
              <template slot-scope="scope">
                <el-button
                  v-if="scope.row.transportLicenseImageUrlList.length > 0"
                  type="text"
                  size="small"
                  @click="transport(scope.row)"
                  >{{
                    scope.row.transportLicenseImageUrlList.length
                  }}</el-button
                >
                <el-button v-else type="text" size="small" disabled
                  >0</el-button
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="hasFuel"
              align="center"
              label="油量监测"
            >
              <template slot-scope="scope">{{
                scope.row.hasFuel | isNull
              }}</template>
            </el-table-column>
            <el-table-column
              prop="deviceName"
              width="150px"
              label="设备号"
            >
              <template slot-scope="scope">{{
                scope.row.deviceName | isNull
              }}</template>
            </el-table-column>
            <el-table-column
              prop="simNumber"
              width="150px"
              label="SIM卡号"
            >
              <template slot-scope="scope">{{
                scope.row.simNumber | isNull
              }}</template>
            </el-table-column>

            <el-table-column
              label="操作"
              width="120"
              align="center"
            >
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="onEdit(scope.row)"
                  >编辑</el-button
                >
                <el-button type="text" size="small" @click="onDelete(scope.row)"
                  ><span style="color: #f00">删除</span></el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div style="margin-top: 20px; text-align: right">
          <el-pagination
            @size-change="mainSizeChange"
            @current-change="mainCurrentChange"
            :current-page.sync="mainCurrentPage"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="10"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalNum"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <el-dialog
      title="驾驶证"
      :visible.sync="carImageDialogVisible"
      width="1000px"
      center
    >
      <div class="content-main">
        <div
          class="order-img"
          :style="{ 'margin-right': (idx + 1) % 3 === 0 ? '0px' : '' }"
          v-for="(item, idx) in carImage"
          :key="idx"
        >
          <el-image
            :preview-src-list="[item]"
            :src="item"
            fit="cover"
            style="width: 100%; height: 100%; border-radius: 4px"
          ></el-image>
          <i
            class="el-icon-error close-icon"
            v-if="item.isClear"
            @click="clickClearImg(idx)"
          ></i>
        </div>
      </div>
    </el-dialog>
    
    <el-dialog
      title="行驶证"
      :visible.sync="driverDialogVisible"
      width="1000px"
      center
    >
      <div class="content-main">
        <div
          class="order-img"
          :style="{ 'margin-right': (idx + 1) % 3 === 0 ? '0px' : '' }"
          v-for="(item, idx) in driverLicenseImageUrlList"
          :key="idx"
        >
          <el-image
            :preview-src-list="[item]"
            :src="item"
            fit="cover"
            style="width: 100%; height: 100%; border-radius: 4px"
          ></el-image>
          <i
            class="el-icon-error close-icon"
            v-if="item.isClear"
            @click="clickClearImg(idx)"
          ></i>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="运输证"
      :visible.sync="transportDialogVisible"
      width="1000px"
      center
    >
      <div class="content-main">
        <div
          class="order-img"
          :style="{ 'margin-right': (idx + 1) % 3 === 0 ? '0px' : '' }"
          v-for="(item, idx) in transportLicenseImageUrlList"
          :key="idx"
        >
          <el-image
            :preview-src-list="[item]"
            :src="item"
            fit="cover"
            style="width: 100%; height: 100%; border-radius: 4px"
          ></el-image>
          <i
            class="el-icon-error close-icon"
            v-if="item.isClear"
            @click="clickClearImg(idx)"
          ></i>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      nav: [
        { name: "车辆管理", path: "" },
        { name: "车辆信息", path: "" },
      ],
      options: [
        { label: "自有-智连", value: 0 },
        { label: "租赁", value: 1 },
        { label: "挂靠", value: 2 },
        { label: "外请", value: 3 },
        { label: "自有-三正", value: 4 },
      ],
      options1: this.$enums.OrderStatus.list,

      query: {
        carBelong: null,
        queryContent: null,
        currentPage: 1,
        pageSize: 10,
      },

      tableData: [],
      loading: true,
      mainCurrentPage: 1,
      totalNum: 0,

      carImageDialogVisible: false,
      driverDialogVisible: false,
      transportDialogVisible: false,
      driverLicenseImageUrlList: [], //行驶证
      carImage: [], //车辆图片
      transportLicenseImageUrlList: [], //运输证
    };
  },
  filters: {
    isNull: (value) => {
      return value !== 0 && !value ? "--" : value;
    },
  },
  watch: {
    query: {
      deep: true,
      handler: function (v, ov) {
        this.getDate(this.query);
      },
    },
  },
  methods: {
    onSearch() {
      this.getDate(this.query);
    },
    clickExport() {},
    // 页条数
    mainSizeChange(val) {
      this.mainCurrentPage = 1;
      this.query.currentPage = 1;
      this.query.pageSize = val;
    },
    // 第几页
    mainCurrentChange(val) {
      this.query.currentPage = val;
    },
    queryChange() {
      this.mainCurrentPage = 1;
      this.query.currentPage = 1;
    },
    getRowClass({ rowIndex }) {
      if (rowIndex === 0) {
        return "background:#FAFAFA;color:#262626;font-weight:600;";
      } else {
        return "";
      }
    },
    // 获取数据
    async getDate(val) {
      this.loading = true;
      let data = await this.$api.listCarSatelliteInfoByCondition(val);
      this.tableData = data.data;
      this.totalNum = data.totalNum;
      this.loading = false;
    },
    // 删除
    async onDelete(val) {
      await this.$confirm(`您确认删除【${val.plateNumber}】吗?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      });
      await this.$api.deleteCarSatelliteInfoByPlateNumber({
        plateNumber: val.plateNumber,
      });
      this.$notify({
        title: "成功",
        message: "删除成功",
        type: "success",
      });
      await this.getDate(this.query);
    },
    // 编辑
    onEdit(value) {
      this.$router.push({
        path: "/vehicle-management/edit-car",
        query: {
          plateNumber: value.plateNumber,
        },
      });
    },
    //车辆图片
    async carData(val) {
      this.carImageDialogVisible = true;
      this.carImage = val.carImageUrlList;
      // await this.$nextTick(() => {
      //   this.$search(this.carImage);
      // });
    },

    //行驶证
    async driver(val) {
      this.driverDialogVisible = true;
      this.driverLicenseImageUrlList = val.driverLicenseImageUrlList;
      // await this.$nextTick(() => {
      //   this.$search(this.driverLicenseImageUrlList);
      // });
    },
    //运输证
    async transport(val) {
      this.transportDialogVisible = true;
      this.transportLicenseImageUrlList = val.transportLicenseImageUrlList;
      // await this.$nextTick(() => {
      //   this.$search(this.transportLicenseImageUrlList);
      // });
    },
  },
  mounted() {
    this.getDate(this.query);
  },
};
</script>

<style lang='sass' scoped>
.ayl-item 
  display: flex;
  align-content: center;
  margin-right: 20px;
  width: 20%;
  max-width: 300px;

.ayl-item > span 
  margin-right: 10px;
  line-height: 28px;

.item 
  flex: 1;

.el-button--mini 
  height: 28px;


/deep/ .el-upload
    vertical-align: top

.avatar-uploader .el-upload:hover
    border-color: #409EFF

.avatar-uploader-icon 
    font-size: 28px
    color: #8c939d
    width: 300px
    height: 150px
    line-height: 150px
    text-align: center
  
.avatar 
    width: 300px
    height: 150px
    display: block
    border: 1px solid rgba(217,217,217,1)
    border-radius: 4px
    background: rgba(250,250,250,1)
    position: relative
    z-index: 0

.avatar-item
    height: 70px
    position: absolute
    z-index: 1
    top: 38px
    left: 102px

.avatar-item-license 
    height: 74px
    position: absolute
    z-index: 1
    top: 38px
    left: 114px

.avatar-icon 
    width: 48px
    height: 48px
    display: table-cell
    margin: 0 auto

.avatar-text 
    text-align: center
    font-size: 12px
    font-family: Microsoft YaHei
    font-weight: 400
    color: rgba(191,191,191,1)

.avatar-img 
    width: 300px
    height: 150px

.order-img
    position: relative
    width: calc((100% - 2 * 13px) / 3)
    height: 138px
    display: inline-block
    margin-right: 13px
    margin-bottom: 14px
    border-radius: 4px
.close-icon
  position: absolute
  top: -5px
  right: -8px
  font-size: 14px
  cursor: pointer

/deep/ .el-image-viewer__close
  color: #fff
/deep/ .el-image-viewer__next,
/deep/ .el-image-viewer__prev
  border: 3px solid #fff
</style>